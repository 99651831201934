import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import * as styles from '../styles/services.module.css'

export default function Services() {
    return (
        <Layout>
            <section className={styles.services}>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        Legalisation of documents for Embassy purpose
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            If the country in which you wish to present your documents has not signed the Hague Convention for use of Apostille, the you will need to contact the Embassy of that country.
                        </p>
                        <p className="paragraph">
                            If you do not know the contact details of your nearest Embassy you can use our <a href="http://www.spanish-translators.co.uk/consulates-embassies-london.htm" target="_blank" rel="noreferrer">list of consulates and embassies in London</a>. 
                        </p>
                        <p className="paragraph">
                            If you need help with guidance and advise, please do not hesitate to <Link to="/contact">contact us</Link>.
                        </p>
                    </div>
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            As a general rule (although not applicable to all countries) you still need to have an Apostille attached to the document and make an appointment with the embassy of that country in order to obtain further legalisation through the embassy of that country. This process can be costly and frustrating and this is where we step in.
                        </p>
                        <p className="paragraph">
                            To avoid any doubt, please contact the embassy that you intend to present the document to in order to find our what their exact requirements are.
                        </p>
                    </div>
                </div>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        Other services
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            Once you have contacted the embassy it is likely that they will require a certified translation or a notarised translation of your documents and possibly of your Apostille before they can legalise your documents.
                        </p>
                    </div>
                    <div className="col-1-of-2">
                        <p className="paragraph">
                            We have a great deal of experience in the field of translations and we can service over 150 languages. For more information and costs to provide the translation and legalisation service please contact us. We offer great discounts if all service is required through us.
                        </p>
                    </div>
                </div>
                <div className="seperator"></div>
                <div className="u-center-text u-margin-bottom-big">
                    <h2 className="heading-secondary">
                        Why use our services?
                    </h2>
                </div>
                <div className="row">
                    <div className="col-1-of-2">
                        <h3 className="heading-tertiary u-margin-bottom-medium">
                            There are many benefits of using our service but we chose to cherry pick a few
                        </h3>
                        <ul>
                            <li>
                                <p className="paragraph">
                                    we take pride in our work and our <Link to="/review">testimonials and reviews</Link> can be viewed by anyone.
                                </p>
                            </li>
                            <li>
                                <p className="paragraph">
                                    You can call us on <a href="tel:+448453889263">0845 388 9263</a> and obtain free advice by telephone. We also offer after hours service. If you are shy, you can contact us by email;
                                </p>
                            </li>
                            <li>
                                <p className="paragraph">
                                    No need to press one and then two and then wait to be connected to a member of staff. There are no call centres. All are calls are answered by real advisors based in the UK;
                                </p>
                            </li>
                            <li>
                                <p className="paragraph">
                                    You can visit our office in person  during our office hours (Monday - Friday 9am - 5.30pm) to hand deliver your documents;
                                </p>
                            </li>
                            <li>
                                <p className="paragraph">
                                    We aim to answer emails within 15-20 minutes from receipt offering you a quick and efficient service;
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className="col-1-of-2">
                        <ul>
                            <li>
                                <p className="paragraph">
                                    Our staff is properly trained and can offer advice on how best to process your document;
                                </p>
                            </li>
                            <li>
                                <p className="paragraph">
                                    We can advise on how to present your document giving you the advantage of our Expert knowledge;
                                </p>
                            </li>
                            <li>
                                <p className="paragraph">
                                    No extra charge on assistance in obtaining the correct documents. After all, we are here to help;
                                </p>
                            </li>
                            <li>
                                <p className="paragraph">
                                    If you are in a hurry or you need to know where we are at, we can offer progress reports by email;
                                </p>
                            </li>
                            <li>
                                <p className="paragraph">
                                    We can email you when your document is ready;
                                </p>
                            </li>
                            <li>
                                <p className="paragraph">
                                    If our <Link to="/apostille">standard delivery option</Link> does not fit your needs we can send the documents by your preferred method. 
                                    Please contact us for a quote or view our <Link to="/cost">cost</Link> page for more info. We also work with a few couriers, cherry picked for you, for international delivery;
                                </p>
                            </li>
                            <li>
                                <p className="paragraph">
                                    Assurance that we do not use any third party companies to legalise your documents.. You can rest assured that your document will be processed securely through the FCO.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </Layout>
    )
}